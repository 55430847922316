import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { FAQSection } from '@shared/types';
import ControlledAccordion from 'common/ControlledAccordion';
import PageSchema from 'common/PageSchema';
import './FaqSection.scss';
import Button from 'common/Button';
import Section from 'common/Section';

const FaqSection: FC<FAQSection.IFAQSectionProps> = ({
  text,
  color,
  accordion,
  button,
  isSchemaEnable,
}) => {
  const dataForPageSchema = accordion?.map((item) => ({
    label: item?.properties?.label,
    content:
      typeof item?.properties?.content === 'string'
        ? item?.properties?.content
        : item?.properties?.content?.map((el) => el.properties.text)?.join(' '),
  }));

  return (
    <Section name="faq" color={color} intro={text}>
      <>
        {accordion?.length ? <ControlledAccordion items={accordion} /> : null}
        {button?.length ? (
          <div className="faq-section__button">
            <Button
              to={button[0].properties.link[0]?.url}
              ariaLabel={button[0].properties.ariaLabel}
              variant="primary-link"
              iconSuffix="chevron-right"
            >
              {button[0].properties.link[0]?.name}
            </Button>
          </div>
        ) : null}
        {!!accordion?.length && isSchemaEnable && (
          <PageSchema additionalData={{ faqData: dataForPageSchema }} type="FAQ" />
        )}
      </>
    </Section>
  );
};

export const query = graphql`
  fragment FragmentFaqSection on TFAQSection {
    properties {
      text
      accordion {
        ...FragmentFaqAccordionProps
      }
      button {
        properties {
          link {
            name
            url
          }
        }
      }
      showInMenu
      anchorName
      anchorTitle
      isSchemaEnable
      color {
        label
      }
    }
    structure
  }

  fragment FragmentFaqAccordionProps on TFAQAccordionItemStructure {
    properties {
      ariaLabel
      label
      link {
        url
      }
      content {
        properties {
          isHorizontal
          showNumber
          text
          steps {
            properties {
              description
              imageAutoHeight
              image {
                properties {
                  imageAlt
                  image {
                    ...FragmentGatsbyProps
                    gatsbyImage {
                      childImageSharp {
                        fluid(maxWidth: 500) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
              link {
                properties {
                  ariaLabel
                  link {
                    name
                    url
                    target
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FaqSection;
